import axios from "../api/axios";
import {
  formatTickPickData,
  getEventIdFromUrl,
  parsePrice,
  parseSection,
} from "../utils/ticketUtils";
import { Ticket, TicketMap, TickPickTicket } from "../types/ticketTypes";
import { useQuery } from "@tanstack/react-query";
import {
  GAMETIME_TICKET_URL,
  TICKPICK_TICKET_URL,
  VIVIDSEATS_TICKET_URL,
} from "../constant";

const fetchStubhubTickets = async (eventUrl: string) => {
  // const res = await axios.get(
  //   `${STUBHUB_TICKET_URL}/stubhub/?eventUrl=${eventUrl}`
  // );

  return [];
};

const fetchTickpickTickets = async (eventUrl?: string | null) => {
  const eventId = getEventIdFromUrl("tickpick", eventUrl);
  if (!eventId) return null;

  const res = await axios.get(`${TICKPICK_TICKET_URL}/?eventId=${eventId}`);
  return res.data.result;
};

const fetchGameTimeTickets = async (eventId?: string | null) => {
  try {
    const response = await axios.get(
      `${GAMETIME_TICKET_URL}/?eventId=${eventId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching tickets:", error);
    return [];
  }
};

const fetchVividSeatsTickets = async (eventUrl?: string | null) => {
  const eventId = getEventIdFromUrl("vivid", eventUrl);
  if (!eventId) return null;
  try {
    const vividSeatsTicketRes = await axios.get(
      `${VIVIDSEATS_TICKET_URL}/?eventId=${eventId}`
    );
    return vividSeatsTicketRes.data.result;
  } catch (e) {
    console.error("failed to parse vividseats result", e);
    return null;
  }
};

export const useFetchStubhub = (eventObject: any, stubhubEventUrl: string) => {
  return useQuery<TicketMap>({
    queryKey: [
      "fetchStubhubEvent",
      {
        eventId: eventObject.id,
      },
    ],
    queryFn: async () => {
      console.log("fetching stubhub tickets", stubhubEventUrl);
      const ticketData = await fetchStubhubTickets(stubhubEventUrl);
      return ticketData.reduce((obj: TicketMap, ticket: any) => {
        const sectionKey = parseSection(ticket.Section);
        const rowKey = ticket.Row;
        const key = `${sectionKey}-${rowKey}`;
        const currentTicket = {
          section: sectionKey,
          row: rowKey,
          url: stubhubEventUrl + `?listingId=${ticket.Id}`,
          price: parsePrice(ticket.Price),
          priceWithFees: parsePrice(ticket.PriceWithFees || ticket.Price),
          minQuantity: Number(ticket.QuantityRange.split("-")[0]),
          maxQuantity:
            ticket.QuantityRange.split("-").length > 1
              ? Number(ticket.QuantityRange.split("-")[1])
              : Number(ticket.QuantityRange),
        };

        return {
          ...obj,
          [key]:
            obj[key] && obj[key].price < currentTicket.price
              ? obj[key]
              : currentTicket,
        };
      }, {});
    },
    refetchOnWindowFocus: false,
  });
};

export const useFetchGameTime = (gametimeEventId: any) => {
  return useQuery<any[]>({
    queryKey: [
      "fetchGametimeEvent",
      {
        eventId: gametimeEventId,
      },
    ],
    queryFn: async () => {
      if (!gametimeEventId) return [];

      const ticketData = await fetchGameTimeTickets(gametimeEventId);
      return ticketData?.result;

      // return ticketData.reduce((obj: TicketMap, ticket: Ticket) => {
      //   const sectionKey = ticket.section;
      //   const rowKey = ticket.row;
      //   const key = `${sectionKey}-${rowKey}`;
      //   const currentTicket = ticket;

      //   return {
      //     ...obj,
      //     [key]:
      //       obj[key] && obj[key].price < currentTicket.price
      //         ? obj[key]
      //         : currentTicket,
      //   };
      // }, {});
    },
    refetchOnWindowFocus: false,
  });
};

export const useFetchTickpick = (tickpickUrl: string) => {
  return useQuery({
    queryKey: [
      "fetchTickpickEvent",
      {
        eventUrl: tickpickUrl,
      },
    ],
    queryFn: async () => {
      const ticketData = await fetchTickpickTickets(tickpickUrl);
      return ticketData as TickPickTicket[];
    },
    refetchOnWindowFocus: false,
  });
};

export const useFetchVividSeats = (vividSeatsUrl: string) => {
  return useQuery<any[]>({
    queryKey: [
      "fetchVividSeatsEvent",
      {
        eventUrl: vividSeatsUrl,
      },
    ],
    queryFn: async () => {
      const ticketData = await fetchVividSeatsTickets(vividSeatsUrl);
      if (!ticketData) return [];

      return ticketData;
    },
    refetchOnWindowFocus: false,
  });
};
