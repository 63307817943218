import {
  ComparisonData,
  MarkupFee,
  QTY_FILTER_VALUES,
  SeaticMapTicket,
  TicketMap,
  TickPickTicket,
} from "../types/ticketTypes";
import { ADD_LOGO_JS, EVENT_LISTENER_JS } from "./SeaticMapJs";

const filterComparisonBySection = (
  comparisonData: ComparisonData,
  selectedSections: string[]
): ComparisonData => {
  const newComparisonData: ComparisonData = {};

  Object.keys(comparisonData).forEach((key) => {
    if (selectedSections.includes(comparisonData[key].section)) {
      newComparisonData[key] = comparisonData[key];
    }
  });

  return newComparisonData;
};

// Providers Keys
export type TicketSource = "stubhub" | "tickpick" | "vividSeats" | "gametime";

export const filterTickets = (
  tickets: ComparisonData,
  qty?: number,
  sections?: string[]
): ComparisonData => {
  const sectionFiltered =
    sections && sections.length > 0
      ? filterComparisonBySection(tickets, sections)
      : tickets;

  const filteredTickets: ComparisonData = {};

  Object.keys(sectionFiltered).forEach((key) => {
    const ticket = sectionFiltered[key];

    if (!qty) {
      filteredTickets[key] = ticket;
      return;
    }

    if (ticket.quantity.includes("-")) {
      const [minQty, maxQty] = ticket.quantity.split("-").map(Number);
      if (qty >= minQty && qty <= maxQty) {
        filteredTickets[key] = ticket;
      }
    } else {
      const intQty = parseInt(ticket.quantity, 10);
      if (qty === QTY_FILTER_VALUES[QTY_FILTER_VALUES.length - 1]) {
        if (intQty >= qty) {
          filteredTickets[key] = ticket;
        }
      } else if (intQty === qty) {
        filteredTickets[key] = ticket;
      }
    }
  });

  // Proceed to sorting the filtered tickets
  return sortTickets(filteredTickets);
};

// Helper function to sort tickets based on the lowest priceWithFees and stubhub presence
function sortTickets(tickets: ComparisonData): ComparisonData {
  const sources: TicketSource[] = [
    "stubhub",
    "tickpick",
    "vividSeats",
    "gametime",
  ];

  const entries = Object.entries(tickets).map(([key, ticket]) => {
    // Calculate the lowest priceWithFees among the available providers
    let lowestPrice = Infinity;
    sources.forEach((source) => {
      const sourceTicket = ticket[source];
      if (sourceTicket && sourceTicket.priceWithFees != null) {
        const price =
          typeof sourceTicket.priceWithFees === "number"
            ? sourceTicket.priceWithFees
            : parseFloat(sourceTicket.priceWithFees);
        if (price < lowestPrice) {
          lowestPrice = price;
        }
      }
    });

    return {
      key,
      ticket,
      lowestPrice,
    };
  });

  // Sort the entries based on the presence of stubhub and lowest priceWithFees
  entries.sort((a, b) => a.lowestPrice - b.lowestPrice);

  // Reconstruct the sorted ComparisonData object
  const sortedComparisonData: ComparisonData = {};

  entries.forEach(({ key, ticket }) => {
    sortedComparisonData[key] = ticket;
  });

  return sortedComparisonData;
}
export const convertToSeaticMapTicket = (
  ticketsData: TickPickTicket[]
): SeaticMapTicket[] => {
  return ticketsData.map((ticket) => {
    return {
      tgUserSec: ticket.section_id,
      tgUserRow: ticket.row,
      tgQty: ticket.quantity,
      tgPrice: ticket.price,
      tgID: parseInt(ticket.id),
    };
  });
};

function updateTicketUrl(ticketUrl: string, ticketId: string): string {
  // Parse the ticketUrl to get the current 'u' parameter
  const url = new URL(ticketUrl);
  const urlParams = new URLSearchParams(url.search);
  const eventUrl = urlParams.get("u");

  if (eventUrl) {
    // Parse the eventUrl to manipulate its query parameters
    const eventUrlObj = new URL(eventUrl);
    const eventUrlParams = new URLSearchParams(eventUrlObj.search);

    // Add or update the 'showDetails' parameter
    eventUrlParams.set("showDetails", ticketId);

    // Reconstruct the eventUrl with updated parameters
    eventUrlObj.search = eventUrlParams.toString();

    // Update the 'u' parameter in the original ticketUrl
    urlParams.set("u", eventUrlObj.toString());
    url.search = urlParams.toString();
  }

  return url.toString();
}

export const combineTicketsForMap = (
  tickpickData?: TickPickTicket[],
  tickpickEventId?: string,
  vividSeatsData?: any[],
  vividSeatsEventUrl?: string,
  gametimeData?: any[]
) => {
  const seaticMapTickets = [];

  if (tickpickData) {
    const tickpickTickets: SeaticMapTicket[] = tickpickData.map((ticket) => {
      return {
        tgUserSec: ticket.section_id,
        tgUserRow: ticket.row,
        tgQty: ticket.quantity,
        tgPrice: ticket.price,
        tgID: parseInt(ticket.id),
        ticketUrl: `https://www.tickpick.com/checkout?e=${tickpickEventId}&listingId=${ticket.id}&listingType=TP&price=${ticket.price}&s=${ticket.section_id}&r=${ticket.row}&utm_source=impact&utm_medium=Cameron%20Roth&utm_campaign=3912002&utm_content=Online%20Tracking%20Link_656088`,
        ticketLogoUrl: "/static/images/providers_logos/tickpick_original.png",
        ticketLogoAlt: "TickPick Logo",
      };
    });
    seaticMapTickets.push(...tickpickTickets);
  }

  if (vividSeatsData) {
    const vividSeatsTickets: SeaticMapTicket[] = vividSeatsData
      ? vividSeatsData.map((ticket) => {
          const ticketUrl = `${vividSeatsEventUrl}&showDetails=${ticket.Id}`;
          const updatedTicketUrl = updateTicketUrl(
            ticketUrl,
            ticket.Id.toString()
          );

          let price =
            ticket.PriceWithFees === ticket.Price || !ticket.PriceWithFees
              ? ticket.Price * VIVID_SEATS_AVERAGE_FEE
              : ticket.PriceWithFees;

          return {
            tgUserSec: ticket["Section"],
            tgUserRow: ticket.Row,
            tgQty: parseInt(ticket["QuantityRange"]),
            tgPrice: parseInt(price),
            tgID: parseInt(ticket.Id.toString().replace(/\D/g, "")),
            ticketUrl: updatedTicketUrl,
            ticketLogoUrl:
              "/static/images/providers_logos/vividseats_original.png",
            ticketLogoAlt: "VividSeats Logo",
          };
        })
      : [];

    seaticMapTickets.push(...vividSeatsTickets);
  }

  // Add GameTime tickets
  if (gametimeData) {
    console.log({
      gametimeData,
    });
    const gametimeTickets: SeaticMapTicket[] = gametimeData.map((ticket) => {
      return {
        tgUserSec: ticket.section,
        tgUserRow: ticket.row,
        tgQty: ticket.minQuantity || 1,
        tgPrice: ticket.priceWithFees || ticket.price,
        tgID: parseInt(
          ticket.url.split("/listings/")[1]?.split("?")[0] ||
            Math.random().toString().slice(2)
        ),
        ticketUrl: ticket.url,
        ticketLogoUrl: "/static/images/providers_logos/gametime_original.png",
        ticketLogoAlt: "GameTime Logo",
      };
    });
    seaticMapTickets.push(...gametimeTickets);
  }

  return seaticMapTickets.sort((a, b) =>
    a.tgUserSec.localeCompare(b.tgUserSec)
  );
};

export const getAddTicketsJS = (seaticMapTickets: SeaticMapTicket[]) => {
  return `
    var ticketsData = ${JSON.stringify(seaticMapTickets)};
    ${ADD_LOGO_JS}
    ${EVENT_LISTENER_JS}

    var checkoutFunc = function (ticketGroup, quantity) {
      var tgId = ticketGroup.tgID;
      console.log({
        tgId,
      });
      var ticket = ticketsData.find((ticket) => ticket.tgID === tgId);
      window.parent.postMessage(
        {
          ticketUrl: ticket.ticketUrl,
          quantity: quantity,
          type: 'open-tickpick',
        },
        "${process.env.REACT_APP_SITE_URL}",
      );

    };

    Seatics.TrackingEvents.registerEventListener(userEventListener);


    Seatics.addTicketData(ticketsData);
    console.log("Seatics.addTicketData");
   
   

    Seatics.Presentation.redirectToCheckout = checkoutFunc
  `;
};

export const parseSection = (sectionId: string) => {
  try {
    // extract just the digits
    const sectionNumbers = sectionId.replace(/\D/g, "");
    if (sectionNumbers.trim().length > 0) {
      // if section id has numbers, only compare numbers (112 vs Upper level 112)
      return sectionNumbers;
    }

    return sectionId
      .toLowerCase()
      .replace("section", "")
      .replace("lower deck", "") // handle this case individually, as it's rare
      .replace("upper deck", "") // handle this case individually, as it's rare
      .trim();
  } catch (e) {
    console.error("error parsing section: ", e);
    return sectionId;
  }
};

export const formatTickPickData = (tickPickData: any, eventId: string) => {
  return tickPickData.reduce((obj: TicketMap, ticket: any) => {
    const sectionKey = parseSection(ticket.section_id);
    const rowKey = ticket.row;
    const key = `${sectionKey}-${rowKey}`;
    const currentTicket = {
      section: sectionKey,
      row: rowKey,
      url: `https://www.tickpick.com/checkout?e=${eventId}&listingId=${ticket.id}&listingType=TP&price=${ticket.price}&s=${ticket.section_id}&r=${ticket.row}&utm_source=impact&utm_medium=Cameron%20Roth&utm_campaign=3912002&utm_content=Online%20Tracking%20Link_656088`,
      price: ticket.price,
      priceWithFees: ticket.price,
      minQuantity: ticket.quantity,
      maxQuantity: ticket.quantity,
    };

    return {
      ...obj,
      [key]:
        obj[key] && obj[key].price < currentTicket.price
          ? obj[key]
          : currentTicket,
    };
  }, {});
};

export const parsePrice = (price: string) =>
  Number(price.replace("$", "").replace(",", "").trim());

export function calculateMarkupFee(
  ticketPrice: number,
  markupFees: MarkupFee[]
): number {
  let markupPercentage = 0;

  for (const fee of markupFees) {
    if (ticketPrice >= fee.from && ticketPrice <= (fee.to ?? Infinity)) {
      markupPercentage = fee.percentage;
      break;
    }
  }

  const markupFee = (ticketPrice * markupPercentage) / 100;

  return markupFee;
}

export const getEventIdFromUrl = (
  provider: string,
  eventUrl?: string | null
) => {
  if (!eventUrl) {
    return null;
  }

  const splitUrl = eventUrl.split("?");

  if (splitUrl.length < 2) {
    return null;
  }

  const urlParams = new URLSearchParams(splitUrl[1]);
  const eventId = urlParams.get("prodsku");

  return eventId;
};

export const VIVID_SEATS_AVERAGE_FEE = 1.5;
export const formatVividSeatsData = (ticketData: any, eventId: string) => {
  if (!ticketData) {
    return {};
  }

  return ticketData.reduce((obj: TicketMap, ticket: any) => {
    const sectionKey = parseSection(ticket.Section);
    const rowKey = ticket.Row;
    const key = `${sectionKey}-${rowKey}`;
    const currentTicket = {
      section: sectionKey,
      row: rowKey,
      url: `https://vivid-seats.pxf.io/c/3912002/952533/12730?1&u=https://www.vividseats.com/production/${eventId}?showDetails=${ticket.Id}`,
      price: ticket.Price,
      priceWithFees:
        ticket.PriceWithFees === ticket.Price || !ticket.PriceWithFees
          ? ticket.Price * VIVID_SEATS_AVERAGE_FEE
          : ticket.PriceWithFees,
      id: ticket.Id,
      minQuantity: Number(ticket.QuantityRange.split("-")[0]),
      maxQuantity:
        ticket.QuantityRange.split("-").length > 1
          ? Number(ticket.QuantityRange.split("-")[1])
          : Number(ticket.QuantityRange),
    };

    return {
      ...obj,
      [key]:
        obj[key] && obj[key].price < currentTicket.price
          ? obj[key]
          : currentTicket,
    };
  }, {});
};
