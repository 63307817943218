import { useState, useEffect } from "react";
import axios from "../api/axios";

let locationPromise: Promise<GeolocationPosition> | null = null;

const NEW_YORK_LONG_LAT = {
  latitude: 40.7128,
  longitude: -74.006,
  city: "New York",
  state: "NY",
  country_code: "US",
};

const HOUSTON_LONG_LAT = {
  latitude: 29.7604,
  longitude: -95.3698,
  city: "Houston",
  state: "TX",
  country_code: "US",
};

function getLocationOnce(): Promise<GeolocationPosition> {
  if (!locationPromise) {
    locationPromise = new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error("Geolocation not supported"));
        return;
      }

      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }
  return locationPromise;
}

export function useGeolocation() {
  const [location, setLocation] = useState<{
    latitude: number | null;
    longitude: number | null;
    city?: string;
    state?: string;
    country_code?: string;
  }>({
    latitude: null,
    longitude: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    getLocationOnce()
      .then(async (position) => {
        const coords = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };

        try {
          // Get city and country information from reverse geocoding API
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/reverse-geocode`,
            {
              params: coords,
            }
          );

          setLocation({
            ...coords,
            ...response.data,
          });
        } catch (err) {
          console.error("Error fetching location details:", err);
          setLocation(coords);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("Unable to retrieve location", err);
        setLocation(NEW_YORK_LONG_LAT);
        setError(err.message);
        setLoading(false);
      });

    // setLocation(HOUSTON_LONG_LAT);
    // setLoading(false);
  }, []);

  return { location, loading, error };
}
