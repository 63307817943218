import React, { useEffect, useRef, useState } from "react";
import { SeaticMapTicket } from "../types/ticketTypes";

// Custom ticket list component
const TicketList = ({ tickets }: { tickets: any[] }) => {
  return (
    <div className="w-full max-w-xl p-4">
      {tickets.map((segment) => (
        <div key={segment.title || "main"}>
          {segment.title && (
            <h3 className="text-lg font-bold mb-2">{segment.title}</h3>
          )}
          {segment.tickets.map((ticket: any) => (
            <div
              key={ticket.tgID}
              className="border rounded p-4 mb-2 hover:bg-gray-50"
              onMouseEnter={() => {
                // Highlight corresponding section on map
                if ((window as any).Seatics?.MapComponent) {
                  (window as any).Seatics.MapComponent.highlightTicket(ticket);
                }
              }}
            >
              <div className="flex justify-between">
                <div>
                  <p className="font-bold">
                    Section {ticket.getSectionDisplayName()}
                  </p>
                  <p>Row {ticket.getRowDisplayName()}</p>
                  <p>{ticket.tgQty} Tickets Available</p>
                </div>
                <div>
                  <p className="text-xl font-bold">${ticket.tgPrice}</p>
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                    onClick={() => {
                      // Handle checkout
                      if ((window as any).Seatics?.Presentation) {
                        (window as any).Seatics.Presentation.redirectToCheckout(
                          ticket,
                          ticket.tgQty
                        );
                      }
                    }}
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const SeaticsMap = ({
  eventId,
  websiteConfigId,
  consumerKey,
  ticketData,
}: {
  eventId: string;
  websiteConfigId: string;
  consumerKey: string;
  ticketData: SeaticMapTicket[];
}) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [ticketSegments, setTicketSegments] = useState([]);

  useEffect(() => {
    // Load required Seatics scripts
    const loadScripts = async () => {
      console.log("loadScripts");
      // Load jQuery if not already loaded
      if (!(window as any).jQuery) {
        const jqueryScript = document.createElement("script");
        jqueryScript.src = "https://code.jquery.com/jquery-3.6.0.min.js";
        document.head.appendChild(jqueryScript);
        await new Promise((resolve) => (jqueryScript.onload = resolve));
      }

      // Load Mapwidget framework
      const frameworkScript = document.createElement("script");
      frameworkScript.src = "https://mapwidget3.seatics.com/Api/framework";
      document.head.appendChild(frameworkScript);
      await new Promise((resolve) => (frameworkScript.onload = resolve));

      // Initialize map
      const initializeMap = () => {
        fetch(
          `https://www.tn-apis.com/maps/v3/EventAndVenueInfo?callback=handleMapData&websiteConfigId=${websiteConfigId}&consumerKey=${consumerKey}&eventId=${eventId}`
        )
          .then((response) => response.text())
          .then((text) => {
            // Execute the JSONP response
            (window as any).handleMapData = (data1: any, data2: any) => {
              console.log("handleMapData", {
                data1,
                data2,
              });
              const mapData = data1[0];
              const eventData = data1[1];

              if (!mapData || !eventData) return;

              // Create map component
              (window as any).Seatics.MapComponent.create({
                imgSrc: eventData.mapImage,
                tickets: ticketData,
                mapData: mapData,
                vfsUrl: "https://vfs.seatics.com",
                container: mapContainerRef.current,
                presentationInterface: {
                  updateTicketsList: (segments: any) => {
                    setTicketSegments(segments);
                  },
                },
                mapWidth: 525,
                mapHeight: 545,
                mapName: eventData.mapName,
                eventInfo: eventData,
              });
            };

            // Execute JSONP
            const script = document.createElement("script");
            script.text = text;
            document.head.appendChild(script);
          });
      };

      initializeMap();
    };

    loadScripts();
  }, [eventId, websiteConfigId, consumerKey, ticketData]);

  return (
    <div className="flex">
      <div ref={mapContainerRef} id="tn-maps" className="w-full h-[900px]" />
      <TicketList tickets={ticketSegments} />
    </div>
  );
};

export default SeaticsMap;
