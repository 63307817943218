export const ADD_LOGO_JS = `
  var addLogoToTd = (td) => {
    if (td.querySelector('img')) {
      return;
    }
    td.classList.add('logo-container-override');
    const img = document.createElement('img');
    
    const parentTR = td.closest('tr')?.parentElement?.closest('tr');
    const dataId = parentTR.getAttribute('data-id');
    const ticket = ticketsData.find((ticket) => ticket.tgID === parseInt(dataId));

    img.src = ticket.ticketLogoUrl;
    img.alt = ticket.ticketLogoAlt;
    img.style.width = 'auto';
    img.style.height = '30px';
    img.style.objectFit = 'contain';
    img.style.marginLeft = '8px';
    td.appendChild(img);
  };
`;

export const EVENT_LISTENER_JS = `
    var userEventListener = function(eventType, eventData) {
      // console.log("Tracking Event " + eventType);
      // console.log(eventData);
      // if (eventType === "FinishedLoading") {

      // }



  // Add logos to existing TDs
  document.querySelectorAll('td.venue-ticket-list-section-qty-col').forEach(addLogoToTd);

  // Create MutationObserver to watch for new TDs
  var observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        // Check if the added node is an element
        if (node.nodeType === Node.ELEMENT_NODE) {
          // If the node itself is a TD with the target class
          if (node.matches('td.venue-ticket-list-section-qty-col')) {
            addLogoToTd(node);
          }
          // Check for matching TDs within the added node
          node.querySelectorAll('td.venue-ticket-list-section-qty-col').forEach(addLogoToTd);
        }
      });
    });
  });

  // Start observing the document body for DOM changes
  observer.observe(document.body, {
    childList: true,
    subtree: true
  });
    };

`;
