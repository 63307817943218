import { FC, ReactNode } from "react";
import { useLocation } from "react-router-dom";

import LocationEvents from "./LocationEvents";
import TrendingPerformers from "./TrendingPerformers";

import "../../../index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface HomeProps {
  children?: ReactNode;
}

const partners = [
  {
    url: "/static/images/providers_logos/gametime_white.png",
    name: "GameTime",
  },
  {
    url: "/static/images/providers_logos/seatgeek_white.png",
    name: "Seatgeek",
  },
  { url: "/static/images/providers_logos/stubhub_white.png", name: "StubHub" },
  {
    url: "/static/images/providers_logos/vividseats_white.png",
    name: "Vivid Seats",
  },
  { url: "/static/images/providers_logos/tickpick_white.png", name: "TicPick" },
];

const Home: FC<HomeProps> = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <div className="flex flex-col items-center bg-white">
      <LocationEvents />
      <TrendingPerformers />
    </div>
  );
};

export default Home;

function PartnerImage({ url, name }: { url: string; name: string }) {
  return (
    <img
      src={url}
      className="h-auto max-h-12 min-h-0 w-auto min-w-0 mx-4 md:mx-8 my-2"
      alt={name}
    />
  );
}
