import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../api/axios";
import { useGeolocation } from "../../../hooks/useGeolocation";
import { useNavigate } from "react-router-dom";

interface Event {
  id: string;
  city: string;
  state: string;
  country_code: string;
  event_id: string;
  name: string;
  venue_name: string;
  image_url: string;
  start_date: string;
  local_event_date_time: string;
  min_ticket_price: string;
  cached_at: string;
  expires_at: string;
}

interface LocationInfo {
  city: string;
  state: string;
  country_code: string;
}

interface TopEventsResponse {
  events: Event[];
  location: LocationInfo;
}

const fetchTopEvents = async (
  latitude: number | null,
  longitude: number | null,
  timeFilter: string
): Promise<TopEventsResponse> => {
  if (!latitude || !longitude)
    return { events: [], location: { city: "", state: "", country_code: "" } };

  const response = await axios.get(
    `${process.env.REACT_APP_BASE_URL}/api/top-events`,
    {
      params: { latitude, longitude, startIn: timeFilter },
    }
  );
  return response.data;
};

// Format date to match the screenshot (Month Day format, no year)
const formatEventDate = (dateString: string) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });
};

const LocationEvents = () => {
  const [timeFilter, setTimeFilter] = React.useState("7");
  const { location: userLocation, loading: locationLoading } = useGeolocation();
  const navigate = useNavigate();

  // Query for events using top-events endpoint
  const { data: eventsData, isLoading: eventsLoading } =
    useQuery<TopEventsResponse>({
      queryKey: ["topEvents", userLocation, timeFilter],
      queryFn: () =>
        fetchTopEvents(
          userLocation.latitude,
          userLocation.longitude,
          timeFilter
        ),
      enabled: !!userLocation.latitude && !!userLocation.longitude,
      retry: false,
      refetchOnWindowFocus: false,
    });

  const handleTimeFilter = (newFilter: string) => {
    if (newFilter !== null) {
      setTimeFilter(newFilter);
    }
  };

  // Navigate to event detail page
  const handleEventClick = (eventId: string | number) => {
    navigate(`/events/${eventId}`);
  };

  // Use fake events if no real events are available
  const events = eventsData?.events || [];
  const displayEvents: Event[] = events.length > 0 ? events : [];

  const headerLocationInfo = userLocation || eventsData?.location;
  const locationHeader =
    headerLocationInfo?.city && headerLocationInfo?.state ? (
      <span>
        Events in{" "}
        <span className="text-[#1d71b8]">
          {headerLocationInfo.city}, {headerLocationInfo.state}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 inline-block ml-1 text-[#1d71b8]"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      </span>
    ) : (
      "Events Near You"
    );

  // Only show loading for events, not for location header
  const isPageLoading = eventsLoading || locationLoading;
  const isHeaderLoading = locationLoading;

  return (
    <div className="w-full max-w-7xl mx-auto px-4 py-8">
      <div className="flex flex-col gap-4 md:flex-row justify-between items-center mb-6">
        <div className="flex items-center">
          <h2 className="text-2xl font-medium">
            {isHeaderLoading ? "Loading Events..." : locationHeader}
          </h2>
        </div>
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            onClick={() => handleTimeFilter("7")}
            className={`px-4 py-2 text-sm font-medium border ${
              timeFilter === "7"
                ? "bg-gray-100 text-gray-900"
                : "bg-white text-gray-700"
            } rounded-l-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700`}
          >
            Next 7 Days
          </button>
          <button
            onClick={() => handleTimeFilter("30")}
            className={`px-4 py-2 text-sm font-medium border-t border-b ${
              timeFilter === "30"
                ? "bg-gray-100 text-gray-900"
                : "bg-white text-gray-700"
            } hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700`}
          >
            Next 30 Days
          </button>
          <button
            onClick={() => handleTimeFilter("60")}
            className={`px-4 py-2 text-sm font-medium border ${
              timeFilter === "60"
                ? "bg-gray-100 text-gray-900"
                : "bg-white text-gray-700"
            } rounded-r-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700`}
          >
            Next 60 Days
          </button>
        </div>
      </div>

      {isPageLoading ? (
        <div className="flex justify-center items-center py-20">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <>
          {displayEvents.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
              {displayEvents.map((event: Event) => (
                <div
                  key={event.event_id}
                  className="overflow-hidden cursor-pointer hover:shadow-lg transition-shadow duration-300 rounded-lg"
                  onClick={() => handleEventClick(event.event_id)}
                >
                  <img
                    src={event.image_url || "/static/images/events/event1.jpg"}
                    alt={event.name}
                    className="w-full h-48 object-cover rounded-lg"
                  />
                  <div className="mt-2 p-2">
                    <h3
                      className="text-lg font-medium line-clamp-2"
                      title={event.name}
                    >
                      {event.name}
                    </h3>
                    <p className="text-sm text-gray-600">
                      {formatEventDate(event.local_event_date_time)} ·{" "}
                      {event.venue_name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center py-20 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-16 w-16 text-gray-400 mb-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                />
              </svg>
              <h3 className="text-xl font-medium text-gray-700 mb-2">
                No events found
              </h3>
              <p className="text-gray-500 max-w-md">
                We couldn't find any events in your area for the selected time
                period. Try adjusting your filters or check back later.
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LocationEvents;
