import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "nprogress/nprogress.css";
import App from "./App";
import { SidebarProvider } from "./contexts/SidebarContext";
import * as amplitude from "@amplitude/analytics-browser";

amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY as string, {
  instanceName: process.env.REACT_APP_AMPLITUDE_INSTANCE_NAME as string,
});

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>
);
