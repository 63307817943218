import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../api/axios";
import { useNavigate } from "react-router-dom";
import { useGeolocation } from "../../../hooks/useGeolocation";

interface Performer {
  id: string;
  name: string;
  image?: string;
  category?: string;
  categoryId?: number;
  imageUrl?: string;
  url?: string;
  imageCreditName?: string;
  isFavorite?: boolean;
  aggregateFavorites?: number;
}

interface TrendingResponse {
  result: {
    items: Performer[];
  };
}

const TrendingPerformers = () => {
  const [categoryId, setCategoryId] = useState("2"); // Default to Sports (2)
  const navigate = useNavigate();

  const { location: userLocation, loading: userLocationLoading } =
    useGeolocation();

  // Categories array with label and value
  const categories = [
    { label: "Sports", value: "2" },
    { label: "Concerts", value: "3" },
    { label: "Theater", value: "1" },
  ];

  // Query for trending performers
  const { data, isLoading } = useQuery<TrendingResponse>({
    queryKey: [
      "trendingPerformers",
      {
        categoryId,
        lat: userLocation.latitude,
        lng: userLocation.longitude,
      },
    ],
    queryFn: async () => {
      const { latitude, longitude, city, country_code } = userLocation;
      const response = await axios.get(
        `${process.env.REACT_APP_FASTAPI_URL}/trendingevents?categoryId=${categoryId}&lat=${latitude}&lng=${longitude}&city=${city}&country=${country_code}`
      );
      return response.data;
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!userLocation.latitude && !!userLocation.longitude,
  });

  const handleCategoryChange = (newCategoryId: string) => {
    setCategoryId(newCategoryId);
  };

  const handlePerformerClick = (performer: Performer) => {
    // Use categoryId if available, otherwise fall back to id
    const id = performer.id;
    navigate(`/performers/${id}`);
  };

  // Map category IDs to their display names - using the categories array
  const categoryMap = Object.fromEntries(
    categories.map((category) => [category.value, category.label])
  );

  return (
    <div className="w-full max-w-7xl mx-auto px-4 py-8">
      <div className="flex flex-col gap-4 md:flex-row justify-between items-center mb-6">
        <div className="flex items-center">
          <h2 className="text-2xl font-medium flex items-center">
            Trending{" "}
            <span className="ml-2">
              <img src="/static/images/trendy.svg" alt="" />
            </span>
          </h2>
        </div>
        <div className="inline-flex rounded-md shadow-sm" role="group">
          {categories.map((category, index) => (
            <button
              key={category.value}
              onClick={() => handleCategoryChange(category.value)}
              className={`px-4 py-2 text-sm font-medium border ${
                categoryId === category.value
                  ? "bg-gray-100 text-gray-900"
                  : "bg-white text-gray-700"
              } ${
                index === 0
                  ? "rounded-l-lg"
                  : index === categories.length - 1
                  ? "rounded-r-lg"
                  : "border-t border-b"
              } hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700`}
            >
              {category.label}
            </button>
          ))}
        </div>
      </div>

      {isLoading || userLocationLoading ? (
        <div className="flex justify-center items-center py-20">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {data?.result?.items?.map((performer) => (
            <div
              key={performer.id || performer.categoryId}
              className="flex items-center p-4 bg-[#f1f1f1] rounded-lg cursor-pointer hover:shadow-md transition-shadow"
              onClick={() => handlePerformerClick(performer)}
            >
              <div className="w-12 h-12 rounded-full overflow-hidden bg-gray-200 flex-shrink-0">
                {performer.image || performer.imageUrl ? (
                  <img
                    src={performer.imageUrl || performer.image}
                    alt={performer.name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-blue-100 text-blue-500">
                    {performer.name.charAt(0)}
                  </div>
                )}
              </div>
              <div className="ml-4 flex-grow">
                <h3 className="font-medium">{performer.name}</h3>
              </div>
              <div className="text-xs text-gray-500 px-2 py-1 border border-gray-300 rounded-full">
                {categoryMap[categoryId as keyof typeof categoryMap]}
              </div>
              <div className="ml-2 bg-[#1d71b8] rounded-full p-1">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 18L15 12L9 6"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TrendingPerformers;
