import { useContext, useEffect, useRef, useState } from "react";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ActionButton from "../../../components/ActionButton";

import { Box, alpha, IconButton, lighten, Typography } from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import { useCookies } from "react-cookie";
import { SidebarContext } from "../../../contexts/SidebarContext";
import { Theme, useTheme, styled } from "@mui/material/styles";
import Logo from "../../../components/LogoSign";

import { Link, useLocation } from "react-router-dom";
import SearchBox from "../../../content/pages/home/SearchBox";
import LoginDialog from "./LoginDialog";
import { DialogContext } from "../../../contexts/DialogContext";
import axios from "../../../api/axios";
import RegisterModal from "./RegisterModal";
import AddOrderDialog from "../../../content/pages/cashback/AddOrderDialog";
import ViewOrdersDialog from "../../../content/pages/cashback/ViewOrdersDialog";
import WithdrawDialog from "../../../content/pages/cashback/WithdrawDialog";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import SettingsDialog from "../../../content/pages/home/SettingsDialog";

const HeaderWrapper = styled(Box)(
  ({ theme }: { theme: any }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #e0e0e0;
        margin-top: 30px;
`
);

const TopBanner = styled(Box)(
  ({ theme }: { theme: any }) => `
        height: 30px;
        background-color: #1d71b8;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 6;
`
);

// Navigation links array
const navigationLinks = [
  { path: "https://www.ticketmaster.com", label: "Ticketmaster" },
  {
    path: "https://stubhub.prf.hn/click/camref:1100lu4Bv/destination:https%3A%2F%2Fstubhub.prf.hn%2Fclick%2Fcamref%3A1100lu4Bv",
    label: "Stubhub",
  },
  {
    path: "https://seatgeek.pxf.io/c/3912002/1753574/20501",
    label: "SeatGeek",
  },
  {
    path: "https://vivid-seats.pxf.io/c/3912002/952533/12730",
    label: "Vivid Seats",
  },
  {
    path: "https://tickpick.dgrk2e.net/c/3912002/656088/10353",
    label: "Tickpick",
  },
];

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme() as Theme;
  const dialogs = useContext(DialogContext);
  const [cookies, setCookie, removeCookie] = useCookies();
  const location = useLocation();

  const [balance, setBalance] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const menuRef = useRef(null);

  useEffect(() => {
    // checkAuth();
    // getBalance();
  }, []);

  async function checkAuth() {
    try {
      const token = localStorage.getItem("token") || cookies["token"] || "";
      const response = await axios.get("/api/users/auth", {
        headers: {
          token,
        },
      });
      if (response.status === 200) {
        setIsLoggedIn(true);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  async function getBalance() {
    try {
      const response = await axios.get("/api/users/balance");
      if (response.status === 200) {
        setBalance(response.data.balance);
      }
    } catch (error) {
      console.warn(error);
    }
  }

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  function toggleSearch() {
    setIsSearchOpen(!isSearchOpen);
  }

  function handleViewOrders() {
    dialogs.openDialog("cashbackViewOrders");
  }

  function handleAddOrder() {
    dialogs.openDialog("cashbackAddOrder");
  }

  function handleWithdraw() {
    dialogs.openDialog("cashbackWithdraw");
  }

  function handleSettings() {
    dialogs.openDialog("settings");
  }

  async function handleSignOut() {
    await signOut(getAuth());
    localStorage.removeItem("token");
    removeCookie("token");
    setIsLoggedIn(false);
  }

  const sports = window.app_preload.sports;
  const sportPerformerEntities = window.app_preload
    .sportPerformerEntities as Record<string, unknown>[];
  const performersGrouped = sportPerformerEntities
    .filter((e) => e.role !== null)
    .reduce((acc: Map<string, any[]>, e: any) => {
      const performers = acc.get(e.sportEntity.code);
      if (performers !== undefined) {
        performers.push(e);
      } else {
        acc.set(e.sportEntity.code, [e]);
      }
      return acc;
    }, new Map());
  const sportMenuOptions = sports.map((sport: any) => {
    const performers = performersGrouped.get(sport.code) || [];
    return {
      id: sport.code,
      name: sport.name,
      to: `/sports/${sport.code}`,
      links: performers
        .map((performer: any) => {
          return {
            id: performer.id,
            name: performer.name,
            href: `/performers/${performer.id}`,
          };
        })
        .sort((a: any, b: any) =>
          a.name.localeCompare(b.name, "en", { sensitivity: "base" })
        ),
    };
  });

  return (
    <>
      <TopBanner>
        <Typography
          variant="body2"
          color="white"
          sx={{
            fontSize: {
              xs: "13px",
              sm: "14px",
            },
            whiteSpace: "nowrap",
          }}
        >
          Compare event ticket prices with no hidden fees!
        </Typography>
      </TopBanner>
      <HeaderWrapper
        display="flex"
        alignItems="center"
        zIndex={"100 !important"}
        className="bg-white"
      >
        {/* Dialogs */}
        <LoginDialog onClose={(succeeded) => setIsLoggedIn(succeeded)} />
        <RegisterModal onClose={(succeeded) => setIsLoggedIn(succeeded)} />
        <ForgotPasswordDialog />
        <ViewOrdersDialog />
        <AddOrderDialog />
        <WithdrawDialog />
        <SettingsDialog />

        <Box
          display="flex"
          alignItems="center"
          className="w-full max-w-7xl mx-auto"
        >
          {/* Logo - Hidden when search is open on mobile */}
          <div
            className={`flex items-center ${
              isSearchOpen ? "hidden lg:flex" : ""
            }`}
          >
            <Logo />
          </div>

          {/* Search Box - Desktop only (lg screens and up) */}
          <div className="ml-6 flex-grow max-w-md hidden lg:block">
            <SearchBox variant="light" />
          </div>

          {/* Navigation Links - Desktop only (lg screens and up) */}
          <div className="hidden lg:flex items-center ml-auto space-x-6">
            {navigationLinks.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className="text-gray-800 hover:text-primary-btn font-medium"
                target="_blank"
              >
                {link.label}
              </Link>
            ))}

            {/* Contact Us Button */}
            {/* <ActionButton
              text="Contact Us"
              sx={{ width: "auto", padding: "8px 16px" }}
              href="/contact"
            /> */}
          </div>

          {/* Mobile/Tablet Search Button/Input (visible on screens below lg) */}
          <div className={`${isSearchOpen ? "w-full" : "ml-auto"} lg:hidden`}>
            {isSearchOpen ? (
              <div className="flex items-center w-full space-x-2">
                <div className="flex-grow">
                  <SearchBox variant="light" />
                </div>
                <button
                  className="p-2 rounded-full bg-gray-100 text-gray-700"
                  onClick={toggleSearch}
                >
                  <CloseTwoToneIcon fontSize="small" />
                </button>
              </div>
            ) : (
              <button
                className="p-2 rounded-full border border-gray-200 flex items-center justify-center"
                onClick={toggleSearch}
              >
                <SearchRoundedIcon
                  fontSize="medium"
                  className="text-gray-700"
                />
              </button>
            )}
          </div>
        </Box>
      </HeaderWrapper>
    </>
  );
}

export default Header;
