export interface ExternalEventProps {
  eventName: string;
  event: any;
  score: number;
  onClick?: () => void;
}

export type EventTicketComparison = {
  section: string;
  row: string;
  quantity: string;
  stubhub: Ticket;
  gametime: Ticket;
  tickpick: Ticket;
  vividSeats: Ticket;
};
export type ComparisonData = Record<string, EventTicketComparison>;

export type Ticket = {
  section: string;
  row: string;
  url: string;
  price: number;
  priceWithFees: number;
  minQuantity: number;
  maxQuantity: number;
};

export type TicketMap = Record<string, Ticket>;

export type MarkupFee = {
  from: number;
  to?: number;
  percentage: number;
};

export const QTY_FILTER_VALUES = Array.from({ length: 10 }, (_, i) => i + 1);

export type SeaticMapTicket = {
  tgUserSec: string; // Required: section where tickets are located
  tgUserRow: string; // Required: row where tickets are located
  tgUserSeats?: string; // Optional: seat number range (e.g. 101-105)
  tgQty: number; // Required: number of tickets available
  tgPrice: number; // Required: price per ticket
  tgID?: number; // Optional: unique ticket group ID
  tgNotes?: string; // Optional: notes for display (e.g. "Obstructed View")
  tgMark?: number; // Optional: 1 to mark as featured offer
  tgType?: number; // Optional but recommended: ticket group type ID
  tgDeliveryOptions?: string; // Optional: shipping options (EM/ID/MD/LP)
  tgFaceValue?: number; // Optional: face value of tickets if known
  tgPriceSlash?: number; // Optional: "slashed" price to show
  tgSplitRuleId?: number; // Optional: rule ID for valid ticket splits
  tgClientData?: any; // Optional: extra data to pass with ticket group
  tgCType?: string; // Optional: for zones (Z/C/R)
  tgDisclaimers?: string[]; // Optional: legal disclosures for popup
  tgServiceFee?: number; // Optional: flat per-ticket service fee
  tgServiceFeeCalculation?: string; // Optional: mathematical function for service fee
};

export type TickPickTicket = {
  section_id: string;
  row: string;
  quantity: number;
  price: number;
  id: string;
};
