import { useMemo, useEffect, useRef } from "react";
import "./event.css";
import {
  calculateMarkupFee,
  combineTicketsForMap,
  getAddTicketsJS,
  getEventIdFromUrl,
} from "../../../utils/ticketUtils";
import { useFetchSeatmap } from "../../../hooks/useFetchSeatmap";
import {
  useFetchGameTime,
  useFetchStubhub,
  useFetchTickpick,
  useFetchVividSeats,
} from "../../../hooks/useFetchTickets";
import { CircularProgress } from "@mui/material";

const Event: React.FunctionComponent<{ eventObject: any }> = ({
  eventObject,
}) => {
  const existProviders = new Map<string, boolean>();
  const providerScore = new Map<string, number>();

  const events = eventObject.events.filter((event: any) => {
    if (!event) return false;
    const eventProviderName = event.eventProvider.name.toLowerCase();
    if (existProviders.has(eventProviderName)) {
      return false;
    } else {
      existProviders.set(eventProviderName, true);
      return true;
    }
  }) as [];

  (events as Record<string, any>[])
    .filter((event) => event.url)
    .map((e) => {
      e.minPriceWithMarkup = e.minPrice
        ? e.minPrice +
          calculateMarkupFee(e.minPrice, e.eventProvider.markupFees || [])
        : null;
      return e;
    })
    .forEach((event: any, index: number) => {
      const s = Math.max(3, 5 - index);
      providerScore.set(event.eventProvider.name, s);
    });

  const venueName = (events as Record<string, any>[]).find(
    (e: Record<string, any>) => e.venue
  )?.venue?.name;

  const tickpickUrl = (events as Record<string, any>[]).find(
    (e: Record<string, any>) => e.eventProvider?.name === "tickpick"
  )?.url;

  const gametimeEventId = (events as Record<string, any>[]).find(
    (e: Record<string, any>) => e.eventProvider?.name === "gametime"
  )?.id;

  const vividSeatsProvider = eventObject?.events?.find(
    (event: any) => event?.eventProvider?.name === "vividseats"
  );
  const vividSeatsUrl = vividSeatsProvider?.url;

  const { data: seatMapData, isLoading: seatMapLoading } = useFetchSeatmap(
    eventObject.name,
    eventObject.date,
    venueName,
    eventObject.location
  );

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { data: gametimeData, isLoading: gametimeLoading } =
    useFetchGameTime(gametimeEventId);

  const { data: tickpickData, isLoading: tickpickLoading } =
    useFetchTickpick(tickpickUrl);

  const { data: vividSeatsRawData, isLoading: vividSeatsIsLoading } =
    useFetchVividSeats(vividSeatsUrl);

  const vividSeatsEventId = getEventIdFromUrl("vivid", vividSeatsUrl);

  const seaticMapTickets = useMemo(() => {
    if (tickpickLoading || vividSeatsIsLoading || gametimeLoading) return [];
    return combineTicketsForMap(
      tickpickData,
      eventObject.id,
      vividSeatsRawData,
      vividSeatsUrl,
      gametimeData
    );
  }, [
    tickpickData,
    vividSeatsRawData,
    gametimeData,
    eventObject.id,
    vividSeatsEventId,
    tickpickLoading,
    vividSeatsIsLoading,
    gametimeLoading,
  ]);

  useEffect(() => {
    const handler = (
      ev: MessageEvent<{ type: string; message: string; ticketUrl?: string }>
    ) => {
      if (typeof ev.data !== "object") return;
      if (!ev.data.type) return;
      if (ev.data.type === "open-tickpick") {
        if (!ev.data.ticketUrl) return;
        window.open(ev.data.ticketUrl, "_blank");
      }
    };

    if (
      iframeRef.current &&
      iframeRef.current.contentWindow &&
      seatMapData?.length > 0 &&
      seaticMapTickets.length > 0
    ) {
      const iframeDoc = iframeRef.current.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <title>Seatic Map</title>
            <script
              src="https://code.jquery.com/jquery-3.7.1.js"
              integrity="sha256-eKhayi8LEQwp4NKxN+CfCh+3qOVUtJn3QNZ0TciWLP4="
              crossorigin="anonymous"
            ></script>
            <style>
              #tn-maps {
                width: 100%;
              }

              @media (max-height: 46rem) {
                #tn-maps {
                  height: 500px !important;
                }
              }

              @media (min-height: 47rem) {
                #tn-maps {
                  height: 800px !important;
                }
              }

              #sea-header-disclosure {
                display: none;
              }
              .logo-container-override {
                display: flex;
                align-items: center;
              }
              @media screen and (max-width: 991px) {
                .event-info-details-ctn {
                  width: 100% !important;
                }
              }
              img[alt="GameTime Logo"] {
                height: 15px !important;
              }

              body {
                overflow-y: hidden !important;
              }
            </style>
          </head>
          <body>
            <div id="tn-maps"></div>
          </body>
          <script>${seatMapData}</script>
          <script>${getAddTicketsJS(seaticMapTickets)}</script>
        </html>
      `);
      iframeDoc.close();

      window.addEventListener("message", handler);
    }

    return () => window.removeEventListener("message", handler);
  }, [seatMapData, seaticMapTickets]);

  const loadSeatMapData =
    !seatMapLoading &&
    seatMapData &&
    ((tickpickData?.length && tickpickData?.length > 0) ||
      (vividSeatsRawData?.length && vividSeatsRawData?.length > 0) ||
      (gametimeData?.length && gametimeData?.length > 0));

  return (
    <div className="relative flex flex-col px-0 md:px-4">
      {(seatMapLoading ||
        vividSeatsIsLoading ||
        tickpickLoading ||
        gametimeLoading) && (
        <div className="flex justify-center items-center h-screen">
          <CircularProgress />
        </div>
      )}

      <div>
        {loadSeatMapData && (
          <iframe
            ref={iframeRef}
            title="Seat Map"
            className="w-full border-none seatmap-iframe flex-1 pt-4"
          />
        )}
      </div>
    </div>
  );
};

export default Event;
