import { FC, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { useMutation } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import PageContentWrapper from "../../layouts/PageContentWrapper";
import axios from "../../api/axios";

const FooterWrapper = styled("div")(({ theme }) => ({
  textDecoration: "none",
  padding: theme.spacing(4),
  backgroundColor: theme.colors.alpha.white[100],
  borderTop: "1px solid #e0e0e0",
}));

const FooterLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.colors.alpha.black[70],
  fontSize: "14px",
  display: "block",
  marginBottom: "8px",
  "&:hover": {
    color: theme.colors.primary.main,
  },
}));

const navigationLinks = [
  { path: "https://www.ticketmaster.com", label: "Ticketmaster" },
  {
    path: "https://stubhub.prf.hn/click/camref:1100lu4Bv/destination:https%3A%2F%2Fstubhub.prf.hn%2Fclick%2Fcamref%3A1100lu4Bv",
    label: "Stubhub",
  },
  {
    path: "https://seatgeek.pxf.io/c/3912002/1753574/20501",
    label: "SeatGeek",
  },
  {
    path: "https://vivid-seats.pxf.io/c/3912002/952533/12730",
    label: "Vivid Seats",
  },
  {
    path: "https://tickpick.dgrk2e.net/c/3912002/656088/10353",
    label: "Tickpick",
  },
];

const NewsletterInput = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
    backgroundColor: "#fff",
    "& fieldset": {
      borderColor: "#e0e0e0",
    },
    "&:hover fieldset": {
      borderColor: theme.colors.primary.main,
    },
    "& .MuiInputAdornment-positionEnd": {
      marginRight: "8px",
    },
  },
}));

const Footer: FC = () => {
  const [email, setEmail] = useState("");
  const { addToast } = useToasts();

  const { mutate: subscribeToNewsletter, isPending } = useMutation({
    mutationFn: async () => {
      return axios.post("/api/subscribe", { email });
    },
    onSuccess: () => {
      addToast("Successfully subscribed to newsletter!", {
        appearance: "success",
        autoDismiss: true,
      });
      setEmail(""); // Clear the input field after successful subscription
    },
    onError: (error: any) => {
      addToast(
        error.response?.data?.error || "Failed to subscribe. Please try again.",
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    },
  });

  const handleSubscribe = () => {
    if (!email) {
      addToast("Please enter your email address", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    // Simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      addToast("Please enter a valid email address", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    subscribeToNewsletter();
  };

  const socialLinks = [
    { url: "https://linkedin.com", network: "linkedin" },
    { url: "https://facebook.com", network: "facebook" },
    { url: "https://instagram.com", network: "instagram" },
    { url: "https://twitter.com", network: "twitter" },
  ];

  return (
    <FooterWrapper>
      <PageContentWrapper style={{ maxWidth: "100%" }}>
        <Grid
          className="!m-0 max-md:w-full md:!mx-auto md:max-w-screen-xl"
          container
          spacing={4}
        >
          {/* Newsletter Section */}
          <Grid
            item
            xs={12}
            md={3}
            className="!p-4 md:!p-8 flex flex-col items-center md:items-start"
          >
            <Typography variant="h6" gutterBottom>
              Join Newsletter
            </Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <NewsletterInput
                className="w-full md:w-4/5 pr-0"
                placeholder="your@email.com"
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubscribe();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className="rounded-full -mr-6"
                        size="small"
                        onClick={handleSubscribe}
                        disabled={isPending}
                        sx={{
                          padding: "6px",
                        }}
                      >
                        {isPending ? (
                          <div className="w-8 h-8 flex items-center justify-center">
                            <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-gray-500"></div>
                          </div>
                        ) : (
                          <img
                            src="/static/images/send.svg"
                            className="w-8 h-8"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>

          <div className="flex flex-row justify-center mx-auto md:hidden">
            {/* Company Section */}
            <Grid
              item
              xs={12}
              md={3}
              className="!p-8 flex flex-col items-center md:items-start"
            >
              <Typography variant="h6" gutterBottom>
                Company
              </Typography>
              <FooterLink to="/about">About</FooterLink>
              <FooterLink to="/contact">Contact</FooterLink>
              <FooterLink to="/">Home</FooterLink>
            </Grid>

            {/* Categories Section */}
            <Grid
              item
              xs={12}
              md={3}
              className="!p-8 flex flex-col items-center md:items-start"
            >
              <Typography variant="h6" gutterBottom>
                Partners
              </Typography>
              {navigationLinks.map((link, index) => (
                <FooterLink key={index} to={link.path}>
                  {link.label}
                </FooterLink>
              ))}
            </Grid>
          </div>

          <Grid
            item
            xs={12}
            md={3}
            className="!p-8  flex-col items-center md:items-start hidden md:flex"
          >
            <Typography variant="h6" gutterBottom>
              Company
            </Typography>
            <FooterLink to="/about">About</FooterLink>
            <FooterLink to="/contact">Contact</FooterLink>
            <FooterLink to="/">Home</FooterLink>
          </Grid>

          {/* Categories Section */}
          <Grid
            item
            xs={12}
            md={3}
            className="!p-8 flex-col items-center md:items-start hidden md:flex"
          >
            <Typography variant="h6" gutterBottom>
              Partners
            </Typography>
            {navigationLinks.map((link, index) => (
              <FooterLink key={index} to={link.path}>
                {link.label}
              </FooterLink>
            ))}
          </Grid>

          {/* Follow Us Section */}
          <Grid
            item
            xs={12}
            md={3}
            className="!p-8 flex flex-col items-center md:items-start"
          >
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              {socialLinks.map((social, index) => (
                <SocialIcon
                  key={index}
                  url={social.url}
                  network={social.network}
                  style={{ height: 30, width: 30 }}
                  bgColor="#666"
                />
              ))}
            </Box>
          </Grid>
        </Grid>

        {/* Copyright Section */}
        <div className="mt-8 pt-4 border-t border-[#e0e0e0] flex flex-col md:flex-row justify-between items-center gap-4">
          {/* Left Text */}
          <div className="max-w-full md:max-w-[30%] text-center md:text-left text-xs text-black">
            All trademarks, logos, and brand names are the property of their
            respective owners. All company product and service names used in
            this website are for identification purposes only. Use of these
            names, trademarks, and brands does not imply endorsement.
          </div>

          {/* Center Logo - Absolute positioning on desktop to ensure center alignment */}
          <div className="flex justify-center order-first md:order-none md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
            <img
              src="/static/images/logo_small.svg"
              alt="Tixplorer Logo"
              className="h-10 w-auto"
            />
          </div>

          {/* Right Text */}
          <div className="max-w-full md:max-w-[30%] text-center md:text-right text-xs text-black">
            Tixplorer © Copyright 2024
          </div>
        </div>
      </PageContentWrapper>
    </FooterWrapper>
  );
};

export default Footer;
