import React, { useEffect, useRef } from "react";
import axios from "../api/axios";
import { useQuery } from "@tanstack/react-query";
import { SeaticMapTicket, TickPickTicket } from "../types/ticketTypes";
import { demoTicketData } from "../constant";
import CustomSeatMap from "./CustomSeatMap";

const baseHtml = `
<!DOCTYPE html>
<html>
  <head>
    <title>Seatic Map</title>
    <script
      src="https://code.jquery.com/jquery-3.7.1.js"
      integrity="sha256-eKhayi8LEQwp4NKxN+CfCh+3qOVUtJn3QNZ0TciWLP4="
      crossorigin="anonymous"
    ></script>
    <style>
      #tn-maps {
        height: 900px;
        width: 100%;
      }
    </style>
  </head>
  <body>
    <div id="tn-maps"></div>
  </body>
</html>
`;

const convertToSeaticMapTicket = (
  ticketsData: TickPickTicket[]
): SeaticMapTicket[] => {
  return ticketsData.map((ticket) => {
    return {
      tgUserSec: ticket.section_id,
      tgUserRow: ticket.row,
      tgQty: ticket.quantity,
      tgPrice: ticket.price,
      tgID: parseInt(ticket.id),
    };
  });
};

const getAddTicketsJS = (ticketsData: TickPickTicket[]) => {
  const seaticMapTickets: SeaticMapTicket[] = ticketsData.map((ticket) => {
    return {
      tgUserSec: ticket.section_id,
      tgUserRow: ticket.row,
      tgQty: ticket.quantity,
      tgPrice: ticket.price,
      tgID: parseInt(ticket.id),
    };
  });

  return `
  Seatics.addTicketData(${JSON.stringify(seaticMapTickets)});
  console.log("Seatics.addTicketData");
  var userEventListener = function(eventType, eventData) {
    console.log("Tracking Event " + eventType);
    console.log(eventData);
  };
  Seatics.TrackingEvents.registerEventListener(userEventListener);
  `;
};

const fetchSeatMapCode = async (eventId: string) => {
  const res = await axios.get(`/api/seatmap?eventId=${eventId}`);

  return res.data;
};

const getTicketsData = async (eventId: string) => {
  const res = await axios.get(
    `${process.env.REACT_APP_TICKPICK_SCRAPER_URL}?eventId=${eventId}`
  );
  return res.data as { result: TickPickTicket[] };
};

const SeatMap = (props: { eventId: string }) => {
  const { eventId } = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { data, isLoading } = useQuery({
    queryKey: ["seatmap", { eventId }],
    queryFn: () => fetchSeatMapCode(eventId),
    refetchOnWindowFocus: false,
  });

  const { data: ticketsData } = useQuery({
    queryKey: ["mapTickets", { eventId }],
    queryFn: () => getTicketsData(eventId),
    refetchOnWindowFocus: false,
  });

  console.log(ticketsData);

  useEffect(() => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      // Initialize iframe with base HTML
      const iframeDoc = iframeRef.current.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(baseHtml);
      iframeDoc.close();

      // Inject Seatics script if available
      if (data && ticketsData) {
        const script = iframeDoc.createElement("script");
        script.appendChild(iframeDoc.createTextNode(data));
        iframeDoc.head.appendChild(script);

        const addTicketsScript = iframeDoc.createElement("script");
        addTicketsScript.id = "seatic-loaded-js";
        addTicketsScript.appendChild(
          iframeDoc.createTextNode(getAddTicketsJS(ticketsData.result))
        );
        iframeDoc.head.appendChild(addTicketsScript);
      }
    }
  }, [data, ticketsData]);

  return (
    <>
      <div>
        <iframe
          ref={iframeRef}
          title="Seat Map"
          style={{ width: "100%", height: "1000px", border: "none" }}
        />
      </div>
      {ticketsData?.result && (
        <CustomSeatMap
          eventId={eventId}
          websiteConfigId={"27442"}
          consumerKey={"8CtApteKC5FLLpbK0iVEwps3_Cka"}
          ticketData={convertToSeaticMapTicket(ticketsData.result)}
        />
      )}
    </>
  );
};

export default SeatMap;
