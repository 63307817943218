import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Autocomplete,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Input,
  Icon,
  CircularProgress,
} from "@mui/material";
import { dayjs, formatToLocal } from "../../../utils/DateUtils";
import { debounce } from "@mui/material/utils";
import { callAPI } from "../../../api";
import { API_NAMES } from "../../../Enums";
import { AxiosResponse } from "axios";
import { SearchRounded } from "@mui/icons-material";
import { Theme, useTheme, styled } from "@mui/material/styles";
import SuspenseLoader from "../../../components/SuspenseLoader";

interface SearchBoxProps {
  children?: ReactNode;
  variant?: "dark" | "light";
}

interface Option {
  name: string;
  link: string;
  type: string;
}

const InputWrapperLight = styled(InputBase)(({ theme }) => {
  return {
    "input::placeholder": {
      color: theme.colors.alpha.black[100],
      opacity: 0.8,
    },
    fontSize: 16,
  };
});

const InputWrapperDark = styled(InputBase)(({ theme }) => {
  return {
    "input::placeholder": {
      color: theme.colors.alpha.white[100],
      opacity: 1,
    },
    color: theme.colors.alpha.white[100],
    fontSize: 16,
  };
});
function SearchBox({ variant = "light" }: SearchBoxProps) {
  const theme = useTheme() as Theme;
  const navigate = useNavigate();
  const [value, setValue] = useState<Option | null>(null);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const fetch = useMemo(
    () =>
      debounce((request: { q: string }, callback: (results?: any) => void) => {
        callAPI(API_NAMES.SEARCH, request).then((response: AxiosResponse) => {
          callback(response.data);
        });
      }, 400),
    []
  );

  const DEBOUNCE_TIME = 400; // 400 milliseconds
  let debounceTimer: NodeJS.Timeout;

  useEffect(() => {
    if (inputValue === "" || inputValue.length < 3) {
      setOptions([]);
      return undefined;
    }

    // Clear existing timer
    clearTimeout(debounceTimer);

    // Set a new debounce timer
    debounceTimer = setTimeout(() => {
      setLoading(true);
      fetch({ q: inputValue }, (result?: any) => {
        const options: any[] = [];

        result.matchedPerformers.forEach((e: any) => {
          options.push({
            name: e.performerName,
            link: `/performers/${e.performerId}`,
            type: "Performers",
          });
        });

        // [...result.matchedEvents]
        //   .sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf())
        //   .forEach((e: any) => {
        //     options.push({
        //       name:
        //         formatToLocal(e.date, "MMM D").toUpperCase() +
        //         " - " +
        //         e.eventName,
        //       link: e.performerId
        //         ? `/performers/${e.performerId}/events/${e.eventId}`
        //         : `/events/${e.eventId}`,
        //       type: "Events",
        //     });
        //   });

        result.matchedSports.forEach((e: any) => {
          options.push({
            name: e.sportName,
            link: `/sports/${e.sportCode}`,
            type: "Sports",
          });
        });

        setOptions(options);
        setLoading(false);
      });
    }, DEBOUNCE_TIME);

    // Cleanup function
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [inputValue, fetch]);

  const onChange = (option: any) => {
    navigate(option.link);
    setOptions([]);
    setInputValue("");
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();

      const firstEventResult = options.find(
        (option) => option.type === "event"
      );

      if (firstEventResult) {
        navigate(firstEventResult.link);
        setOptions([]);
        setInputValue("");
      }
    }
  };

  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const InputWrapper =
    variant === "dark" ? InputWrapperDark : InputWrapperLight;
  return (
    <Paper
      component="form"
      sx={{
        p: "4px",
        display: "flex",
        alignItems: "center",
        height: 45,
        width: "100%",
        maxWidth: "500px",
        borderRadius: "20px",
        boxShadow: "none",
        border: "1px solid",
        borderColor:
          variant === "dark"
            ? theme.colors.alpha.white[50]
            : theme.colors.alpha.black[50],
        background:
          variant === "dark"
            ? theme.colors.alpha.black[50]
            : theme.colors.alpha.white[10],
      }}
    >
      <Autocomplete
        autoHighlight={true}
        sx={{ ml: 1, flex: 1 }}
        autoComplete
        open={open && inputValue.length > 0}
        loading={loading}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        ListboxProps={{ style: { maxHeight: "60vh" } }}
        groupBy={(option: any) => option.type}
        filterOptions={(x) => x}
        filterSelectedOptions
        noOptionsText={
          inputValue.length < 3 ? "Type at least 3 characters" : "No Results"
        }
        onChange={(_event, option: any) => onChange(option)}
        size="medium"
        renderOption={(props, option: any) => {
          return (
            <li key={option} {...props} style={{ fontSize: 14 }}>
              {option.name}
            </li>
          );
        }}
        getOptionLabel={(option: any) => option.name}
        options={options}
        value={value}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <InputWrapper
            ref={params.InputProps.ref}
            sx={{ width: "110%" }}
            // placeholder="Search for any sport event"
            placeholder="Search by artist or team"
            inputProps={{
              ...params.inputProps,
              onKeyDown,
            }}
          />
        )}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <div
        style={{
          marginRight: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "auto",
          borderRadius: "50%",
          background: "#FFF",
          aspectRatio: "1",
        }}
      >
        {loading ? (
          <CircularProgress size={17} color="inherit" />
        ) : (
          <SearchRounded
            fontSize="small"
            sx={{
              color: "black",
            }}
          />
        )}
      </div>
    </Paper>
  );
}

export default SearchBox;
