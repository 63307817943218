import { useParams } from "react-router-dom";
import APIFetchContainer, {
  VisualComponentProps,
} from "../../../containers/APIFetchContainer";
import { API_NAMES } from "../../../Enums";
import Event from "./event";
import { useEffect, useRef } from "react";

import PageContentWrapper from "../../../layouts/PageContentWrapper";

const VisualComponent: React.FunctionComponent<VisualComponentProps> = ({
  resultData,
}) => {
  if (resultData) {
    return (
      <>
        {/* <BgHeader image={img} /> */}
        <PageContentWrapper>
          <div className="w-full h-full">
            <Event eventObject={resultData} />
          </div>
        </PageContentWrapper>
      </>
    );
  } else {
    return <></>;
  }
};
const Events = () => {
  const { eventId, performerId } = useParams();
  /* const { performerId } = QueryString.parse(
    window.location.hash
  ); */
  const ref = useRef();
  useEffect(() => {
    if (ref.current === undefined) return;
    const s = ref.current as any;
    s.onRequestDataChange({ eventId: eventId, performerId: performerId });
  }, [eventId, performerId]);
  return (
    <APIFetchContainer
      ref={ref}
      apiName={API_NAMES.EVENT}
      needDataFetch={true}
      VisualComponent={VisualComponent}
      requestData={{ eventId: eventId, performerId: performerId }}
    />
  );
};
export default Events;
