import { useQuery } from "@tanstack/react-query";
import axios from "../api/axios";

const formatEventDate = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  //   const hours = String(date.getHours()).padStart(2, "0");
  //   const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}${month}${day}`;
};

export const useFetchSeatmap = (
  eventName: string,
  eventDate: string,
  venueName?: string,
  location?: string
) => {
  return useQuery({
    queryKey: ["seatmap", { eventName, eventDate, venueName, location }],
    queryFn: async () => {
      const formattedDate = formatEventDate(eventDate);
      //   const replacedEventName = eventName.toLowerCase().replace("at", "vs.");

      const res = await axios.get(
        `/api/seatmap?eventName=${eventName}&eventDate=${formattedDate}&venueName=${
          location || venueName
        }`
      );

      //   const res = await axios.get("/api/seatmap?eventId=6261756");
      return res.data;
    },
    refetchOnWindowFocus: false,
  });
};
