export class MlbTeams {
  static readonly NEW_YORK_YANKEES = "NEW_YORK_YANKEES";
}

export const demoTicketData = [
  [
    "",
    "Zone B",
    "",
    8,
    1215.0,
    1827846253,
    "Right Center and Left Center Orchestra, Rows BBB-Y. Right and Left Orchestra, Rows A-X. Center Orchestra, Rows K-Y. .   Tickets will be ready for delivery by Sep 18, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~Nzg0~Nj~I1~My~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~T~Ey~M~T~Umd~Gdjb3~Vud~D04!~Y7e~K~J~H~N~Gl~Nk.",
    "*-*",
    0,
    1,
    "LP",
    1350,
    2,
    "Z",
  ],
  [
    "",
    "Zone E",
    "",
    8,
    945.0,
    1827846325,
    "Mezzanine, Rows D-O. .   Tickets will be ready for delivery by Sep 18, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~Nzg0~Nj~My~N~S~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~Tk0~N~S~Z0~Z2~Nvd~W50~P~Tg.!e~N~Kezrqr~O9s.",
    "*-*",
    0,
    1,
    "LP",
    1050,
    2,
    "Z",
  ],
  [
    "",
    "Zone F",
    "",
    8,
    855.0,
    1827846399,
    "Balcony, Rows P-R. .   Tickets will be ready for delivery by Sep 18, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~Nzg0~Nj~M5~O~S~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~Tg1~N~S~Z0~Z2~Nvd~W50~P~Tg.!wvgh~Lc~A~Oc~Gc.",
    "*-*",
    0,
    1,
    "LP",
    950,
    2,
    "Z",
  ],
  [
    "",
    "Zone G",
    "",
    8,
    765.0,
    1827846471,
    "Balcony, Rows S-V. .   Tickets will be ready for delivery by Sep 18, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~Nzg0~Nj~Q3~M~S~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~Tc2~N~S~Z0~Z2~Nvd~W50~P~Tg.!se~Zc~Iudjlp~E.",
    "*-*",
    0,
    1,
    "LP",
    850,
    2,
    "Z",
  ],
  [
    "",
    "MEZZANINE RIGHT CENTER",
    "J",
    7,
    1475.0,
    1828254565,
    "Tickets will be ready for delivery by Sep 20, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~O~D~I1~N~D~U2~N~S~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~T~E0~Nz~Umd~Gdjb3~Vud~D03!~Sh~Tpsyw~Mi~S~I.",
    "*-*",
    0,
    1,
    "EM",
    1620,
    1,
    "R",
  ],
  [
    "",
    "ORCHLC",
    "W",
    4,
    2026.0,
    1828438076,
    "Tickets will be ready for delivery by Sep 20, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~O~D~Qz~O~D~A3~Ni~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~T~Iw~Mj~Ymd~Gdjb3~Vud~D00!zta~O9~Pk~Za~L~Q.",
    "*-*",
    0,
    1,
    "EM",
    2226,
    1,
    "R",
  ],
  [
    "",
    "ORCHLC",
    "X",
    2,
    1689.0,
    1828438077,
    "Tickets will be ready for delivery by Sep 20, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~O~D~Qz~O~D~A3~Ny~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~T~E2~O~Dkmd~Gdjb3~Vud~D0y!pq~Y~Xmapiel8.",
    "*-*",
    0,
    1,
    "EM",
    1855,
    1,
    "R",
  ],
  [
    "",
    "MEZLFT",
    "B",
    2,
    810.0,
    1829119190,
    "Tickets will be ready for delivery by Sep 18, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~O~T~Ex~O~T~E5~M~C~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~Tgx~M~C~Z0~Z2~Nvd~W50~P~T~I.!p~A~A~Z56x~W~Ok8.",
    "*-*",
    1,
    1,
    "",
    900,
    1,
    "R",
  ],
  [
    "",
    "MEZLFT",
    "B",
    4,
    810.0,
    1829119191,
    "Tickets will be ready for delivery by Sep 18, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~O~T~Ex~O~T~E5~M~S~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~Tgx~M~C~Z0~Z2~Nvd~W50~P~T~Q.!~C~E~C~Vz~Gi~P~K9s.",
    "*-*",
    1,
    1,
    "",
    900,
    1,
    "R",
  ],
  [
    "",
    "MEZLFT",
    "J",
    2,
    630.0,
    1829119363,
    "Tickets will be ready for delivery by Sep 21, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~O~T~Ex~O~T~M2~My~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~T~Yz~M~C~Z0~Z2~Nvd~W50~P~T~I.!~T1r-m~G81~S0k.",
    "*-*",
    1,
    1,
    "",
    699,
    1,
    "R",
  ],
  [
    "",
    "MEZRGT",
    "J",
    2,
    630.0,
    1829119364,
    "Tickets will be ready for delivery by Sep 21, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~O~T~Ex~O~T~M2~N~C~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~T~Yz~M~C~Z0~Z2~Nvd~W50~P~T~I.!fzu3b0ch66w.",
    "*-*",
    1,
    1,
    "",
    699,
    1,
    "R",
  ],
  [
    "",
    "MEZRGT",
    "K",
    4,
    630.0,
    1829119365,
    "Tickets will be ready for delivery by Sep 21, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~O~T~Ex~O~T~M2~N~S~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~T~Yz~M~C~Z0~Z2~Nvd~W50~P~T~Q.!~F5~M6~P~S~Z~M9~I~I.",
    "*-*",
    1,
    1,
    "",
    699,
    1,
    "R",
  ],
  [
    "",
    "MEZZANINE LEFT.",
    "C",
    2,
    981.0,
    1829319071,
    "Tickets will be ready for delivery by Sep 18, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~O~T~Mx~O~T~A3~M~S~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~Tk4~M~S~Z0~Z2~Nvd~W50~P~T~I.!~V~O~M8q-8~A~U2k.",
    "2001-2002",
    0,
    1,
    "EM",
    1090,
    1,
    "R",
  ],
  [
    "",
    "MEZZANINE LEFT.",
    "N",
    2,
    981.0,
    1829319622,
    "Tickets will be ready for delivery by Sep 18, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~O~T~Mx~O~T~Yy~Mi~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~Tk4~M~S~Z0~Z2~Nvd~W50~P~T~I.!4~B~C~W8~X~Q~E~W7~E.",
    "2001-2002",
    0,
    1,
    "EM",
    1090,
    1,
    "R",
  ],
  [
    "",
    "RIGHT MEZZANINE.",
    "N",
    2,
    765.0,
    1829319072,
    "Tickets will be ready for delivery by Sep 18, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~O~T~Mx~O~T~A3~Mi~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~Tc2~N~S~Z0~Z2~Nvd~W50~P~T~I.!n~Q~W~Zsk~Yz~Wq4.",
    "2001-2002",
    0,
    1,
    "EM",
    849,
    1,
    "R",
  ],
  [
    "",
    "MEZZANINE RIGHT.",
    "N",
    2,
    765.0,
    1829319623,
    "Tickets will be ready for delivery by Sep 18, 2018.",
    "~Yn~Jva2~Vya~W~Q9~Njkx~Ni~Zza~X~Rlbn~Vt~Ym~Vy~P~Tk3~Jn~Rna~W~Q9~M~Tgy~O~T~Mx~O~T~Yy~My~Zldn~Rp~Z~D0z~M~Dk3~Nj~A0~Jn~Bya~W~Nl~P~Tc2~N~S~Z0~Z2~Nvd~W50~P~T~I.!t~O2exh~Fjies.",
    "2001-2002",
    0,
    1,
    "EM",
    849,
    1,
    "R",
  ],
];

export const GAMETIME_TICKET_URL = process.env
  .REACT_APP_GAMETIME_SCRAPER_URL as string;
export const VIVIDSEATS_TICKET_URL = process.env
  .REACT_APP_VIVIDSEATS_SCRAPER_URL as string;
export const TICKPICK_TICKET_URL = process.env
  .REACT_APP_TICKPICK_SCRAPER_URL as string;
